<template>
    <div class="px-4 lg:px-0">
        <h1 class="text-4xl font-bold tracking-tight text-gray-900">
            {{ t('inventory') }}
        </h1>
        <!--            <p class="mt-4 text-base text-gray-500">Description Goes Here</p> -->
        <p
            v-if="totalResults !== null"
            class="mt-2 text-gray-700 text-sm font-medium"
        >
            {{ pluralize(totalResults, t('vehicle')) }}
        </p>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const { t } = useI18n()

defineProps({
    totalResults: {
        type: [Number, null] as PropType<number | null>,
        required: true,
    },
})
</script>
