<template>
    <RadioGroup v-model="currentIndex">
        <RadioGroupLabel class="sr-only"> Sort </RadioGroupLabel>

        <div class="relative -space-y-px rounded-md bg-white">
            <RadioGroupOption
                v-for="(option, index) in sortOptions"
                :key="index"
                v-slot="{ checked, active }"
                as="template"
                :value="index"
            >
                <div
                    :class="[
                        checked
                            ? 'z-10 border border-blue-200 bg-blue-50'
                            : 'border-gray-200',
                        index === 0 ? 'border-t' : '',
                        'relative flex cursor-pointer flex-col p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6',
                    ]"
                >
                    <span class="flex items-center text-sm">
                        <span
                            :class="[
                                checked
                                    ? 'bg-blue-600 border-transparent'
                                    : 'bg-white border-gray-300',
                                active
                                    ? 'ring-2 ring-offset-2 ring-blue-600'
                                    : '',
                                'h-4 w-4 rounded-full border flex items-center justify-center',
                            ]"
                            aria-hidden="true"
                        >
                            <span class="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <RadioGroupLabel
                            as="span"
                            :class="[
                                checked ? 'text-blue-900' : 'text-gray-900',
                                'ml-3 font-medium',
                            ]"
                        >
                            {{ option.name }}
                        </RadioGroupLabel>
                    </span>
                </div>
            </RadioGroupOption>
        </div>
    </RadioGroup>
</template>

<script setup lang="ts">
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const inventoryStore = useInventoryStore()

const sortOptions = computed(() => inventoryStore.getFilteredSortOptions)
const currentSort = computed(() => sortOptions.value[currentIndex.value])

const currentIndex = ref(assignOriginalSort())

watch(
    () => currentIndex.value,
    () =>
        inventoryStore.updateSort(
            currentSort.value?.sort === null
                ? null
                : {
                      sort: currentSort.value.sort,
                      sortDirection: currentSort.value.sort_direction ?? null,
                  },
        ),
)

function assignOriginalSort() {
    if (!inventoryStore.sort) {
        return 0
    }

    const findIndex = sortOptions.value.findIndex(
        (item) =>
            item.sort === inventoryStore.sort?.sort &&
            item.sort_direction === inventoryStore.sort?.sortDirection,
    )

    return findIndex > -1 ? findIndex : 0
}
</script>
