<template>
    <div class="flex flex-col text-center space-y-4">
        <div class="h1">We didn't find any exact matches</div>
        <div class="">
            We get more cars every day. Get notified when the one you're looking
            for is available.
        </div>
        <!--        <div class="flex justify-center items-center w-full text-center"> -->
        <!--            <button class="btn">Email me when available</button> -->
        <!--        </div> -->
    </div>
</template>
