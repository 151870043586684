<template>
    <!-- Pagination -->
    <nav
        aria-label="Pagination"
        class="mx-auto mt-6 px-4 text-sm font-medium text-gray-700 sm:px-6 lg:px-8"
    >
        <div class="flex items-center flex-col space-y-4">
            <div>Currently Viewing X of XX Vehicles</div>
            <div>
                <button
                    type="button"
                    class="rounded-full bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    More Vehicles
                </button>
            </div>
        </div>
    </nav>
</template>
