<template>
    <div v-if="shouldPaginate">
        <NumericPagination
            v-if="type === 'default'"
            :meta="meta"
            :chunk="chunk"
            @paginate="emit('paginate', $event)"
        />
        <MorePagination
            v-else
            :meta="meta"
            @paginate="emit('paginate', $event)"
        />
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import MorePagination from '~/components/Shared/Pagination/MorePagination.vue'
import NumericPagination from '~/components/Shared/Pagination/NumericPagination.vue'
import type { InventoryMeta } from '~/utils/types/inventory'

const props = defineProps({
    meta: {
        type: Object as PropType<InventoryMeta>,
        required: true,
    },
    type: {
        type: String,
        default: 'default',
    },
    chunk: {
        type: Number,
        default: 3,
    },
})

const emit = defineEmits(['paginate'])

const shouldPaginate = computed(
    () => props.meta && props.meta.total > props.meta.per_page,
)
</script>
