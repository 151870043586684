<template>
    <div
        v-if="!filtersSyncing"
        :class="[isMobile ? 'block' : 'hidden lg:block']"
    >
        <div class="relative lg:h-full lg:min-h-screen">
            <div
                v-auto-animate
                class="lg:my-8 lg:sticky lg:top-8 lg:z-30 lg:w-80 lg:overflow-y-auto divide-y overflow-x-hidden divide-gray-200 border border-gray-200 rounded-md max-h-screen"
            >
                <div
                    v-if="filterCounts.total > 0 || pills.length > 0"
                    class="bg-gray-100 p-6"
                >
                    <div class="flex justify-between items-center mb-2">
                        <div class="font-semibold">
                            Filters<span v-if="filterCounts.total > 0">
                                ({{ filterCounts.total }})</span
                            >
                        </div>
                        <button
                            class="text-xs cursor-pointer underline underline-offset-4"
                            @click="emit('resetFilters')"
                        >
                            {{ t('clear_all') }}
                        </button>
                    </div>
                    <div class="flex flex-wrap">
                        <Pills
                            :pills="pills"
                            @remove-filter="emit('removeFilter', $event)"
                        />
                    </div>
                </div>

                <div v-if="isMobile" v-auto-animate>
                    <FilterButton
                        name="Sort by"
                        :highlight="currentSort.name"
                        :open="sortFilterOpen"
                        @click="toggle('sort')"
                    />

                    <MobileSort v-if="sortFilterOpen" />
                </div>

                <div v-if="!locationFilter.shouldHide" v-auto-animate>
                    <FilterButton
                        :name="locationFilter.name"
                        :open="locationFilterOpen"
                        :count="filterCounts[locationFilter.id] ?? 0"
                        @click="toggle('location')"
                    />

                    <component
                        :is="locationFilter.component"
                        v-if="locationFilterOpen"
                        :filter="locationFilter"
                        :reset-filter="resetFilter"
                        @update="emit('update', $event)"
                    />
                </div>

                <template
                    v-for="(filter, index) in otherFilters"
                    :key="filter.id"
                >
                    <div
                        v-if="
                            !filter.shouldHide &&
                            (filter.type !== 'facet' ||
                                filter.options !== undefined)
                        "
                        v-auto-animate
                    >
                        <FilterButton
                            :name="filter.name"
                            :open="otherFiltersOpenArr[index]"
                            :count="filterCounts[filter.id] ?? 0"
                            @click="toggle(index)"
                        />

                        <component
                            :is="filter.component"
                            v-if="otherFiltersOpenArr[index]"
                            :filter="filter"
                            :reset-filter="resetFilter"
                            @update="emit('update', $event)"
                        />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { FilterOption } from '~/utils/types/inventoryFilter'
import FilterButton from '~/components/SearchResultsPage/SearchResultsFilter/FilterButton.vue'
import Pills from '~/components/SearchResultsPage/SearchResultsFilter/Pills.vue'
import MobileSort from '~/components/SearchResultsPage/SearchResultsFilter/MobileSort.vue'

const inventoryStore = useInventoryStore()
const filterStore = useInventoryFilterStore()

const props = defineProps({
    filters: {
        type: Array as PropType<FilterOption[]>,
        default: () => [],
    },
    pills: {
        type: Array as PropType<Pill[]>,
        default: () => [],
    },
    filterCounts: {
        type: Object,
        default: () => {},
    },
    query: {
        type: Object as PropType<Record<string, any>>,
        default: () => {},
    },
    resetFilter: {
        type: Object as PropType<Record<string, any> | null>,
        default: null,
    },
    isMobile: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['update', 'resetFilters', 'removeFilter'])

const { t } = useI18n()

const filtersSyncing = computed(() => filterStore.filtersSyncing)
const locationFilter = computed(() => props.filters[0])
const otherFilters = computed(() =>
    locationFilter.value.shouldHide ? props.filters : props.filters.slice(1),
)

const locationFilterOpen = ref(
    !!props.query['location_search_term.lvl0'] ||
        !!props.query['location_search_term.lvl1'],
)
const sortFilterOpen = ref(false)
const otherFiltersOpenArr = ref([] as boolean[])

const currentSort = computed(() => inventoryStore.getCurrentSortOption)

// wait for facets to load to open potential attribute filters
watch(
    () => props.filters,
    () => {
        if (otherFiltersOpenArr.value.length === 0) {
            otherFiltersOpenArr.value = getInitialOpenFilters()
        }
    },
)

// if the reset filter is closed, we have to manually update the facet here
watch(
    () => props.resetFilter,
    (removeFilter) => {
        if (removeFilter !== null) {
            if (removeFilter.type === 'query') {
                emit('update', {
                    filter: removeFilter.filter,
                    type: removeFilter.type,
                    option: removeFilter.option,
                    value: null,
                })
            } else {
                let attributeCategory: string | null = null

                if (removeFilter.filter.toString().startsWith('attribute.')) {
                    const attributeNames = getAttributeNames(
                        removeFilter.filter.toString(),
                    )

                    attributeCategory = attributeNames.category
                }

                // get the index of the reset filter
                const index = (props.filters ?? []).findIndex(
                    (option: FilterOption) =>
                        attributeCategory
                            ? option.id === attributeCategory
                            : removeFilter.range
                              ? option.id === removeFilter.range
                              : option.id === removeFilter.filter ||
                                option.childId === removeFilter.filter,
                )

                if (index > -1) {
                    const isClosed =
                        index === 0
                            ? !locationFilterOpen.value
                            : !otherFiltersOpenArr.value[index]

                    if (!isClosed) {
                        // force remove
                        emit('update', {
                            filter: removeFilter.filter,
                            type: removeFilter.type,
                            option: removeFilter.option,
                            value: null,
                        })
                    } else {
                        const filterObj = props.filters[index]

                        if (filterObj.type === 'facet') {
                            if (attributeCategory) {
                                emit('update', {
                                    filter: removeFilter.filter,
                                    type: filterObj.type,
                                    option: removeFilter.option,
                                    value: false,
                                })
                            } else if (filterObj.childId !== undefined) {
                                const isChild =
                                    filterObj.childId === removeFilter.filter

                                if (isChild) {
                                    emit('update', {
                                        filter: filterObj.childId,
                                        option: removeFilter.option,
                                        value: false,
                                        type: filterObj.type,
                                    })
                                } else {
                                    emit('update', {
                                        filter: filterObj.id,
                                        option: removeFilter.option,
                                        value: false,
                                        type: filterObj.type,
                                        clearChildren: {
                                            key: filterObj.childId,
                                            parent: removeFilter.option,
                                        },
                                    })
                                }
                            } else {
                                emit('update', {
                                    filter: filterObj.id,
                                    type: filterObj.type,
                                    option: removeFilter.option,
                                    value: false,
                                    selectType: filterObj.selectType,
                                })
                            }
                        } else {
                            emit('update', {
                                filter: filterObj.id,
                                type: filterObj.type,
                                value: null,
                            })
                        }
                    }
                }
            }
        }
    },
)

function toggle(index: string | number) {
    index = index.toString()

    if (index === 'location') {
        locationFilterOpen.value = !locationFilterOpen.value
    } else if (index === 'sort') {
        sortFilterOpen.value = !sortFilterOpen.value
    } else {
        index = parseInt(index)
        otherFiltersOpenArr.value[index] = !otherFiltersOpenArr.value[index]
    }
}

function getInitialOpenFilters() {
    return otherFilters.value.map((filter) => filter.open)
}

function getAttributeNames(key: string) {
    const keySplit = key.replace('attribute.', '').split('.')

    return {
        category: keySplit[0],
        class: keySplit[1],
    }
}
</script>
