<template>
    <TextElement
        :name="type"
        autocomplete="off"
        :addons="{
            before: props.prefix ?? undefined,
            after: props.suffix ?? undefined,
        }"
        :value="modelValue"
        inputmode="numeric"
        :mask="
            !disableFormat
                ? {
                      mask: 'number',
                      thousandsSeparator: ',',
                  }
                : null
        "
        :unmask="!disableFormat"
        :add-class="{
            input: 'border-none input-remove-shadow',
        }"
        @blur="attemptUpdate"
        @keyup.enter="$event.target.blur()"
    />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    modelValue: {
        type: [String, Number] as PropType<string | number>,
        required: true,
    },
    prefix: String,
    suffix: String,
    values: Object,
    disableFormat: Boolean,
    error: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['update', 'update:modelValue'])

function attemptUpdate(event: any) {
    emit('update', [event, props.type])
}
</script>
