import { trackAscEvent } from '~/composables/asc/trackAscEvent'
import { type ASCPageType, ASCEvent } from '~/utils/types/asc/enums'
import type { ASCElementConfiguration } from '~/utils/types/asc'

export const trackElementConfigurationEvent = (
    params: ASCElementConfigurationParams,
) => {
    const { pageType, data } = params

    const event: ASCElementConfiguration = {
        page_type: pageType,
        event_action_result: data.event_action_result,
        event_action: data.event_action,
        element_state: data.element_state,
        element_type: data.element_type,
        element_subtype: data.element_subtype,
        element_title: data.element_title,
        element_text: data.element_text,
        element_value: data.element_value,
        element_color: data.element_color ?? null,
        element_order: data.element_order ?? null,
    }

    trackAscEvent({
        eventName: ASCEvent.ELEMENT_CONFIGURATION,
        event,
        pageType,
    })
}

export interface ASCElementConfigurationParams {
    pageType: ASCPageType
    data: Record<string, any>
}
