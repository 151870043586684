<template>
    <div class="mt-6">
        <div class="text-center text-xs">
            Currently viewing {{ meta.from }}-{{ meta.to }} of
            {{ meta.total }} matches
        </div>
        <nav
            aria-label="Pagination"
            class="mx-auto mt-4 flex justify-between px-4 text-sm font-medium text-gray-700 sm:px-6 lg:px-8"
        >
            <div class="min-w-0 flex-1" />
            <div class="space-x-2 flex items-center">
                <button
                    v-if="meta.current_page !== 1"
                    type="button"
                    class="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-2 hover:bg-gray-100"
                    @click="setPage(meta.current_page - 1)"
                >
                    <span class="sr-only">Previous</span>
                    <ChevronLeftIcon class="h-5 w-6" aria-hidden="true" />
                </button>

                <button
                    v-if="showStart"
                    type="button"
                    class="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100"
                    @click="setPage(1)"
                >
                    1
                </button>

                <span
                    v-if="showStart"
                    class="inline-flex h-10 items-center px-1.5 text-gray-500"
                >
                    ...
                </span>

                <button
                    v-for="(page, index) in chunkGroup"
                    :key="index"
                    type="button"
                    class="inline-flex h-10 items-center rounded-md border bg-white px-4 hover:bg-gray-100"
                    :class="
                        page === meta.current_page
                            ? 'border-red-600 ring-1 ring-red-600'
                            : 'border-gray-300'
                    "
                    @click="setPage(page)"
                >
                    {{ page }}
                </button>

                <span
                    v-if="showEnd"
                    class="inline-flex h-10 items-center px-1.5 text-gray-500"
                >
                    ...
                </span>

                <button
                    v-if="showEnd"
                    type="button"
                    class="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-4 hover:bg-gray-100"
                    @click="setPage(meta.last_page)"
                >
                    {{ meta.last_page }}
                </button>

                <button
                    v-if="meta.current_page !== meta.last_page"
                    type="button"
                    class="inline-flex h-10 items-center rounded-md border border-gray-300 bg-white px-2 hover:bg-gray-100"
                    @click="setPage(meta.current_page + 1)"
                >
                    <span class="sr-only">Next</span>
                    <ChevronRightIcon class="h-5 w-6" aria-hidden="true" />
                </button>
            </div>
            <div class="flex min-w-0 flex-1 justify-end" />
        </nav>
    </div>
</template>

<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import type { PropType } from 'vue'
import type { InventoryMeta } from '~/utils/types/inventory'

const props = defineProps({
    meta: {
        type: Object as PropType<InventoryMeta>,
        required: true,
    },
    chunk: {
        type: Number,
        default: 3,
    },
})

const emit = defineEmits(['paginate'])

const meta = computed(() => props.meta as InventoryMeta)
const shouldShowExtras = computed(() => meta.value.last_page > props.chunk)

const showStart = computed(
    () =>
        props.chunk < meta.value.last_page &&
        meta.value.current_page > props.chunk,
)

const showEnd = computed(
    () =>
        props.chunk < meta.value.last_page &&
        meta.value.last_page - props.chunk >= meta.value.current_page,
)

const chunkGroup = computed(() => {
    if (shouldShowExtras.value) {
        const startCheck = meta.value.current_page - Math.floor(props.chunk / 2)
        const endCheck = meta.value.current_page + Math.floor(props.chunk / 2)

        if (startCheck <= 2) {
            return createChunk(1, props.chunk)
        } else if (endCheck + 1 >= meta.value.last_page) {
            return createChunk(
                meta.value.last_page - props.chunk + 1,
                meta.value.last_page,
            )
        } else {
            return createChunk(startCheck, endCheck)
        }
    } else {
        return createChunk(1, meta.value.last_page)
    }
})

function createChunk(start: number, end: number) {
    const returnArr: Array<number> = []

    for (let i = start; i <= end; i++) {
        returnArr.push(i)
    }

    return returnArr
}

function setPage(page: number) {
    if (
        meta.value.current_page !== page &&
        page >= 1 &&
        page <= meta.value.last_page
    ) {
        emit('paginate', page)
    }
}
</script>
