<template>
    <div
        class="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
    >
        <div
            class="animate-pulse aspect-h-3 aspect-w-4 bg-gray-200 group-hover:opacity-75"
        >
            <div
                class="h-full w-full object-cover object-center sm:h-full sm:w-full"
            />
        </div>
        <div class="flex flex-1 flex-col space-y-1 p-4">
            <div class="animate-pulse w-full bg-gray-200 h-6 rounded-full" />
            <div class="animate-pulse w-1/4 bg-gray-200 h-6 rounded-full" />
            <div class="flex flex-1 flex-col justify-end">
                <div class="text-sm text-gray-500 h-4" />
                <div class="animate-pulse w-1/4 bg-gray-200 h-6 rounded-full" />
            </div>
        </div>
    </div>
</template>
