<template>
    <button
        v-for="(pill, index) in filteredPills"
        :key="index"
        type="button"
        class="mr-2 mt-2 inline-flex items-center gap-x-1 rounded-md bg-white px-3 py-2 text-xs sm:text-sm text-gray-600 ring-1 ring-inset ring-gray-500/25 hover:bg-gray-300/10"
        @click="emit('removeFilter', pill)"
    >
        {{ pill.name }}

        <span class="group relative -mr-1 h-3.5 w-3.5 rounded-sm">
            <span class="sr-only">Remove</span>
            <svg
                viewBox="0 0 14 14"
                class="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
            >
                <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
            <span class="absolute -inset-1" />
        </span>
    </button>

    <button
        v-if="!isMobile && shouldShowMoreButton"
        type="button"
        class="mr-2 mt-2 inline-flex items-center gap-x-1 rounded-md bg-black px-3 py-2 text-xs sm:text-sm text-white ring-1 ring-inset ring-gray-500/25 shadow-sm hover:bg-gray-800"
        @click="showMore = !showMore"
    >
        {{
            isCutoff
                ? `Show ${pills.length - filteredPills.length} More`
                : 'Show Less'
        }}

        <component
            :is="isCutoff ? PlusCircleIcon : MinusCircleIcon"
            class="-mr-0.5 h-4.5 w-4.5"
            aria-hidden="true"
        />
    </button>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/vue/24/solid'
import type { Pill } from '~/stores/inventoryFilter'

const props = defineProps({
    pills: {
        type: Array as PropType<Pill[]>,
        default: () => [],
    },
    isMobile: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['removeFilter'])

const pillCutoff: number = 5
const showMore = ref<boolean>(false)

const shouldShowMoreButton = computed<boolean>(
    () => props.pills.length > pillCutoff,
)

const isCutoff = computed<boolean>(
    () => !props.isMobile && !showMore.value && shouldShowMoreButton.value,
)

const filteredPills = computed<Pill[]>(() =>
    isCutoff.value ? props.pills.slice(0, pillCutoff) : props.pills,
)
</script>
